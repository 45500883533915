





import Vue from 'vue';
import apiBackend from '@/service/api-insurance';
import { saveZusammenfassungPDF } from '@/helper/handle-pdf';

interface QueueFunction {
  (): void;
}

export default Vue.extend({
  props: [
    'id',
    'processSavingData',
  ],
  data() {
    return {
      variant: 'variant1',
      queue: [] as QueueFunction[],
    };
  },
  watch: {
    processSavingData(value: boolean) {
      if (!value) {
        this.executeQueue();
      }
    },
  },
  methods: {
    executeQueue() {
      // eslint-disable-next-line no-restricted-syntax
      for (const func of this.queue) {
        func();
      }
      this.queue = [];
    },
    enqueue(functionName: 'sendBigXML' | 'downloadBigXML' | 'saveBasicPDF' | 'downloadPDF' | 'getPriceUseId' | 'getPriceRequestUseId', variant: string) {
      if (variant !== '') {
        this.variant = variant;
      }
      // Define the functions that can be enqueued
      const validFunctions: Record<string, QueueFunction> = {
        sendBigXML: this.sendBigXML.bind(this),
        downloadBigXML: this.downloadBigXML.bind(this),
        saveBasicPDF: this.saveBasicPDF.bind(this),
        downloadPDF: this.downloadPDF.bind(this),
        getPriceUseId: this.getPriceUseId.bind(this),
        getPriceRequestUseId: this.getPriceRequestUseId.bind(this),
        // Add more functions as needed
      };
      const func = validFunctions[functionName];
      if (func) {
        this.queue.push(func);
      }
    },
    sendBigXML() {
      apiBackend.sendBigXML(this.id, {
        variant: this.variant,
      }).then((response) => {
        this.$emit('get-draft-insurance', this.id);
      });
    },
    downloadBigXML() {
      apiBackend.downloadBigXML(this.id, {
        variant: this.variant,
      })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'big_xml.xml');
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    downloadPDF() {
      apiBackend.downloadPDF(this.id).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `Vorschlag-${this.id}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    saveBasicPDF() {
      saveZusammenfassungPDF.call(this, this.id, 'saveBasicPDF', this.goTarifTab);
    },
    goTarifTab() {
      this.$emit('go-to-tab', 'insuranceForms', 2);
    },
    getPriceUseId() {
      this.$emit('get-price-use-id', this.id, this.variant);
    },
    getPriceRequestUseId() {
      apiBackend.getPriceRequestGet(this.id, this.variant).then((response: any) => {
        console.log('getPriceRequestUseId response', response.data);
      }).catch((e) => {
        console.log(`Error - ${e}`);
      });
    },
  },
});
